import React from 'react';

import { CaseHeader, Gallery, GalleryLayout, HeroBanner, Team, TextContent } from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import Seo from '../../components/SEO';

import { ReferenceCategory } from '../../constants';

import socialPreview from '../../../static/social-previews/oneplay.jpg';

const CASE_ID = 'oneplay';
const MODULE_NAME = 'oneplay';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    gradient1: 'linear-gradient(0deg, #010E0E, #010E0E)',
    gradient2: 'linear-gradient(180deg, #06302E 0%, rgba(4, 48, 46, 0.167628) 15.03%, rgba(3, 48, 46, 0) 34.99%)',
    dark: '#090114',
    white: '#FFF',
};

const visibleFromMd = () => ({
    display: 'none',
    tablet: {
        display: 'block',
    },
});
const hiddenFromMd = () => ({
    tablet: {
        display: 'none',
    },
});

const team = [
    {
        department: 'references.case.team.management',
        names: ['Patrik Šonský'],
    },
    {
        department: 'references.case.team.android',
        names: ['Mykhailo Liutov', 'Petr Šíma', 'Tomáš Glázr', 'Michal Pešek'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Lukáš Hromadník', 'Vladislav Komkov', 'Vít Nademlejnský', 'Rostislav Babáček'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Mariya Vorona'],
    },
    {
        department: 'references.case.team.design',
        names: ['Martin Jílek'],
    },
];

interface Props {}

const OneplayCaseStudy = (props: Props) => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            extend={{
                subTitleContainer: () => ({ paddingBottom: '2.5rem' }),
                titleContainer: () => ({
                    marginTop: '25%',
                    desktop: {
                        marginTop: '10%',
                    },
                }),
            }}
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/main.png`,
                    style: {
                        height: '100%',
                    },
                    imgStyle: {
                        top: '0px',
                        objectPosition: 'center',
                    },
                    alt: `case.${CASE_ID}.hero.project`,
                },
            }}
            background={` url("/images/case-studies/assets/oneplay/main-bg.svg") no-repeat 0 0/cover`}
            headerTheme="light"
            textColor={colors.white}
        />

        <TextContent
            headerTheme="light"
            textColor={colors.white}
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.NEWS,
                outputs: ['references.case.output.mobile', 'references.case.output.testing'],
            }}
            background={colors.dark}
            extend={{ categoryLink: () => ({ color: colors.white }) }}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            extend={{
                containerWrapper: () => ({
                    ['&>*:first-child']: { height: 'auto!important' },
                    ['& .gatsby-image-wrapper']: { maxHeight: 'min(100vh, 920px) !important' },
                }),
            }}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/1.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                        },
                    },
                },
            ]}
            background={colors.dark}
        />

        <TextContent
            headerTheme="light"
            textColor={colors.white}
            content={{
                section: `case.${CASE_ID}.section2.name`,
                title: `case.${CASE_ID}.section2.title`,
                text: `case.${CASE_ID}.section2.text`,
            }}
            background={colors.dark}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/2.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                    },
                },
            ]}
            background={colors.dark}
        />

        <TextContent
            headerTheme="light"
            textColor={colors.white}
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
            background={colors.dark}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/3.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                            width: '95%',
                            padding: '0 2.5%',
                        },
                    },
                },
            ]}
            extend={{ containerWrapper: visibleFromMd() }}
            background={colors.dark}
        />
        {/* @ts-expect-error */}
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/3-mobile.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                            width: '95%',
                            padding: '0 2.5%',
                        },
                    },
                },
            ]}
            extend={{ containerWrapper: hiddenFromMd() }}
            background={colors.dark}
        />

        <TextContent
            headerTheme="light"
            textColor={colors.white}
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
            background={colors.dark}
            extend={{
                mainText: () => ({ '& > a:not(:hover)': { color: `${colors.white} !important` } }),
            }}
        />

        {/* @ts-expect-error */}
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            fullHeight={true}
            content={[
                {
                    image: {
                        src: `${IMAGE_PATH}/4.png`,
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center center',
                            height: '80%',
                            top: '10%',
                        },
                    },
                },
            ]}
            background={colors.dark}
            backgroundImage={{
                src: `${IMAGE_PATH}/4-bg.png`,
                imgStyle: {
                    objectFit: 'cover',
                    bottom: '0px',
                    height: '100%',
                    left: 'unset',
                },
            }}
        />
        {/* TODO: přidat fotku + texty */}
        {/* <Testimonial
            headerTheme="dark"
            background={colors.bgLight}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/img_person_jtbanka.jpeg`,
                    },
                },
            }}
            type="PROFILE_RIGHT"
        /> */}

        {/* @ts-expect-error */}
        <Team headerTheme="dark" content={team} background={colors.white} textColor={colors.black} />
    </>
);

export default OneplayCaseStudy;
